/*下拉框样式*/
.home-page .ant-select-dropdown {
	padding: 0px;
}

.home-page .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #efedea;
}

.home-page .ant-select-item,
.home-page .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: #674714;
}


/*检索标签*/
.home-page .ser-tag {
	padding: 16px 0 0 138px;
}

.home-page .ser-tag .ant-tag {
	font-size: 14px;
}

.home-page .ser-tag .ant-tag-checkable-checked,
.home-page .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
	background-color: transparent;
}

.home-page .ant-tag-checkable:active,
.home-page .ant-tag-checkable-checked,
.home-page .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
	color: #92180b;
}

/*研究资料*/

.home-page .bkc-item {
	width: 100%;
}

.home-page .bkc .ant-col h3 {
	width: 100%;
	height: 66px;
	line-height: 66px;
	background: #d3c8a8 url(@/assets/images/title-bg-new.png) no-repeat left top;
	margin-bottom: 0px;
}

.home-page .bkc .ant-col h3 span {
	display: inline-block;
	width: 270px;
	font-size: 26px;
	color: #92180b;
	font-family: 'themeFont';
	text-align: center;
}

.home-page .bkc .ant-col h3 a {
	float: right;
	font-size: 14px;
	color: #674714;
	margin-right: 30px;
}

.home-page .bkc-item .ant-list {
	padding: 15px 30px 0px;
	border: 1px solid #d3c8a8;
}

/*研究咨询、研究观点*/
.home-page .con-item {
	margin-top: 60px;
	margin-bottom: 60px;
}
.home-page .con-box1 {
	margin-top: 60px;
}
.home-page .study-item {
	width: 100%;
}

.home-page .study .ant-col h3 {
	width: 100%;
	height: 66px;
	line-height: 66px;
	background: #d3c8a8 url(@/assets/images/title-bg.png) no-repeat left top;
	margin-bottom: 0px;
}

.home-page .study .ant-col h3 span {
	display: inline-block;
	width: 168px;
	font-size: 26px;
	color: #92180b;
	font-family: 'themeFont';
	text-align: center;
}

.home-page .study .ant-col h3 a {
	float: right;
	font-size: 14px;
	color: #674714;
	margin-right: 30px;
}

.home-page .study-item .ant-list {
	padding: 15px 30px 40px;
	border: 1px solid #d3c8a8;
}

.home-page .study-item .ant-list-split .ant-list-item {
	color: #333;
	border-bottom: 1px dashed #d3c8a8;
	padding-right: 20px;
}

.home-page .study-item .ant-list-item-meta-title>a {
	color: #333;
}

.home-page .study-item .ant-list-item-meta-title>a:hover {
	color: #92180b;
}

.home-page .study-item .ant-list-item-meta-title>a i {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #92180b;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: -2px;
}

/*古今人物*/
.home-page .characters {
	width: 100%;
	min-height: 376px;
	background: url(@/assets/images/characters-bg.png) no-repeat center top;
	background-size: cover;
	margin-bottom: 60px;
}

.home-page .characters .con-box {
	position: relative;
}

.home-page .characters h3 {
	position: relative;
}

.home-page .characters h3 span {
	display: inline-block;
	width: 198px;
	height: 95px;
	padding: 0 30px;
	line-height: 47.5px;
	background: url(@/assets/images/title-bg-red2-new.png) no-repeat left top;
	font-size: 26px;
	color: #fff;
	font-family: 'themeFont';
	margin-top: -16px;
}

.home-page .characters h3 a {
	position: absolute;
	right: 30px;
	top: 30px;
	font-size: 14px;
	color: #674714;
}

.home-page .character-ancient {
	padding-top: 50px;
}

.character-ancient .character-list {
	width: 70%;
	margin-left: -10px;
}

.home-page .characters .ant-card {
	background-color: transparent;
}

.home-page .characters .ant-card-cover img {
	width: 140px;
	height: 140px;
	margin: 0 auto;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid #cec3a3;
}

.home-page .characters .ant-card-meta-title>a {
	color: #674714;
}

.home-page .characters .ant-card-body {
	padding: 10px;
	text-align: center;
}

.home-page .characters .ant-card-meta-detail>div:not(:last-child) {
	margin-bottom: 0px;
}

.home-page .characters .ant-card-meta-description {
	font-size: 12px;
	color: #333;
}

.home-page .ancient-list {
	position: absolute;
	right: 30px;
	top: 50px;
	width: 160px;
}

.home-page .ancient-list .ant-list-items {
	border-top: 1px dashed #d3c8a8;
	border-bottom: 1px dashed #d3c8a8;
}

.home-page .ancient-list .ant-list-item {
	padding: 12px 16px;
	border-bottom: 1px dashed #d3c8a8;
}

.home-page .ancient-list .ant-list-item-meta-title>a {
	font-size: 16px;
	color: #674714;
}

.ancient-list .ant-list-item-meta-title>a i {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #92180b;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: -2px;
}

.home-page .ancient-list .ant-list-item-meta-title>a:hover,
.home-page .characters .ant-card-meta-title>a:hover {
	color: #92180b;
}

.home-page .character-now {
	width: 90%;
	padding: 0 5%;
}

.home-page .character-now .character-list {
	padding-top: 20px;
}

/*推荐书籍*/
.home-page .books h3 {
	text-align: center;
	position: relative;
	margin-bottom: 20px;
}

.home-page .books h3 span {
	font-size: 26px;
	color: #92180b;
	font-family: 'themeFont';
}

.home-page .books h3 a {
	position: absolute;
	top: 10px;
	right: 30px;
	font-size: 14px;
	color: #674714;
}

.home-page .books .ant-list .arrow {
	position: absolute;
	top: 136px;
	display: block;
	width: 26px;
	height: 44px;
}

.home-page .books .ant-list .arrow-l {
	left: -52px;
	background: url(@/assets/images/arrow-l.png) no-repeat left top;
}

.home-page .books .ant-list .arrow-r {
	right: -52px;
	background: url(@/assets/images/arrow-r.png) no-repeat left top;
}

.home-page .books .ant-card-cover {
	padding: 30px;
	background: #efedea;
}

.home-page .books .ant-card-cover img {
	border: 2px solid #d5caac;
	box-shadow: 6px 6px 0 0 #e4e1db;
	cursor: pointer;
}

.home-page .books .ant-card-body {
	padding: 20px 20px 0;
}

.home-page .books .ant-card-meta-title {
	text-align: center;
}

.home-page .books .ant-card-meta-title>a {
	color: #333333;
}

.home-page .books .ant-card-meta-title>a:hover {
	color: #92180b;
}

/*关学时间轴*/
.home-page .time-line {
	width: 100%;
	height: 125px;
	background: #c4b899;
}

.home-page .time-line .con-box {
	height: 125px;
	background: #d3c8a8 url(@/assets/images/time-line-bg.png) no-repeat;
	position: relative;
}

.home-page .time-line .con-box>span {
	padding: 0 30px;
	line-height: 125px;
	font-size: 28px;
	color: #3b2c19;
	font-family: 'themeFont';
}

.home-page .time-line .con-box>span b {
	color: #92180b;
}

.home-page .time-line .con-box .line-con {
	position: absolute;
	left: 394px;
	top: 0px;
	width: 905px;
	padding-top: 52px;
}

.home-page .time-line .con-box .line-con .arrow {
	position: absolute;
	top: 52px;
	width: 13px;
	height: 21px;
}

.home-page .time-line .con-box .line-con .arrow-l {
	left: 0px;
	background: url(@/assets/images/arrow-l-red.png) no-repeat;
}

.time-line .con-box .line-con .arrow-r {
	right: 0px;
	background: url(@/assets/images/arrow-r-red.png) no-repeat;
}

.home-page .time-line .con-box .line-con ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 10px 35px;
	border-top: 2px solid #92180b;
}

.home-page .time-line .con-box .line-con ul li {
	width: 20%;
	list-style: none;
	margin-top: -13px;
	padding-top: 26px;
	font-size: 18px;
	color: #3b2c19;
	font-style: italic;
	text-align: center;
	background: url(@/assets/images/line-dot.png) no-repeat top center;
}


.home-page .figure .con-box {
	background: #eeede9;
	margin-bottom: 24px;
}

.home-page .figure h3 {
	padding: 0 35px;
	height: 65px;
	line-height: 65px;
	border-bottom: 1px solid #d3c8a8;
}

.home-page .figure h3 span {
	font-size: 26px;
	color: #92180b;
	font-family: 'themeFont';
}

.home-page .figure h3 a {
	float: right;
	font-size: 14px;
	color: #674714;
}

.home-page .figure .ant-list {
	padding: 0 56px;
}

.home-page .figure .ant-list-item {
	min-height: 212px;
}

.home-page .figure .ant-list-item-extra {
	position: absolute;
	left: 24px;
	margin-left: 0px;
}

.home-page .figure .ant-list-item-extra img {
	border-radius: 50%;
	border: 1px solid #d3c8a8
}

.home-page .figure .ant-list-item-main {
	margin-left: 220px;
	padding-top: 30px;
}

.home-page .figure .ant-list-item-meta-title a {
	font-size: 18px;
	color: #674714;
}

.home-page .figure .ant-list-item-meta-title a span {
	font-size: 14px;
	color: #333333;
}