/*检索框*/
.uc-page .details-banner .con-box {
	height: 220px;
	background: url(@/assets/images/details-banner-new.png) no-repeat center top;
}
.uc-page .details-banner .con-box h4 {
	font-size: 56px;
	line-height: 56px;
	font-family: 'themeFont';
	color: #641c15;
	text-align: center;
	padding-top: 75px;
}

/*当前位置*/
.uc-page .bread-crumb {
  padding: 20px 0 30px;
}
.uc-page .ant-breadcrumb, 
.uc-page .ant-breadcrumb a {
  color: #333333;
}
.uc-page .ant-breadcrumb a:hover {
  color: #92180b;
}
.uc-page .ant-breadcrumb > span:last-child {
  color: #92180b;
}
.uc-page .main-con {
  padding-bottom: 60px;
}

.uc-page .user-center {
  padding-bottom: 60px;
}
/*用户名、修改密码*/
.uc-page .user-info {
  background: #f8f7f5;
  padding: 30px 30px 0;
  text-align: center;
}
.uc-page .user-info .ant-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #d3c8a8;
  margin-bottom: 20px;
}
.uc-page .user-info .user-name {
  font-size: 18px;
  display: block;
  padding-bottom: 10px;
}

.uc-page .user-info .modify-password {
  height: 60px;
  line-height: 60px;
  background: #efedea;
  font-size: 18px;
  color: #674714;
  margin: 0 -30px;
  display: block;
}
/*个人中心导航*/
.uc-page .user-nav {
	background: #f8f7f5;
  margin-top: 30px;
}
.uc-page .user-nav h3 {
	width: 100%;
	height: 66px;
	background: #d3c8a8;
	margin-bottom: 0px;
}
.uc-page .user-nav h3 span {
	display: inline-block;
	width: 198px;
	height: 66px;
	padding: 0 10px;
	line-height: 66px;
	background: url(@/assets/images/title-bg-red.png) no-repeat left top;
	font-size: 26px;
	color: #fff;
	font-family: 'themeFont';
}
.uc-page .user-nav h4 {
  font-size: 18px;
  color: #674714;
  padding: 15px 40px;
  margin-bottom: 0px;
}
.uc-page .user-nav .ant-menu {
  background-color: transparent;
  border-right: none;
  padding: 0 40px;
  padding-bottom: 30px;
}
.uc-page .user-nav .ant-menu-item::after {
  display: none;
}
.uc-page .user-nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.uc-page .user-nav .ant-menu-item {
  padding: 0px !important;
  border-bottom: 1px dashed #d3c8a8;
  margin: 0px;
  height: 50px;
  line-height: 50px;
}
.uc-page .user-nav .ant-menu-item i {
  display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #92180b;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: -2px;
}
.uc-page .user-nav .ant-menu-item .menu-arrow {
  float: right;
  width: 14px;
  height: 10px;
  background: url(@/assets/images/menu-arrow.png) no-repeat;
  vertical-align: middle;
  margin-top: 20px;
  display: none;
}
.uc-page .user-nav .ant-menu-item:hover, 
.uc-page .user-nav .ant-menu-item-selected {
  color: #92180b;
  font-weight: bold;
}
.uc-page .user-nav .ant-menu-item:hover .menu-arrow,
.uc-page .user-nav .ant-menu-item-selected .menu-arrow{
  display: block;
}
.uc-page .user-nav .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}


.uc-page .collect-resources h3 {
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 1px solid #d3c8a8;
	font-size: 26px;
  line-height: 26px;
	color: #92180b;
	font-family: 'themeFont';
}
.uc-page .collect-resources .ant-list-split .ant-list-item {
	border-bottom: none;
	display: block;
}
.uc-page .collect-resources .ant-list-item-meta-title {
	font-size: 20px;
	margin-bottom: 10px;
}
.uc-page .collect-resources .ant-list-item-meta-title > a:hover {
	color: #92180b;
}
.uc-page .collect-resources .ant-list-item-meta-description > a,
.uc-page .collect-resources .ant-list-item-meta-description span {
	color: #674714;
  margin-right: 20px;
}
.uc-page .collect-resources .ant-list-split .ant-list-item .brief {
	padding-top: 10px;
	font-size: 16px;
	line-height: 28px;
}