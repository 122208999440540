/*当前位置*/
.search-page .bread-crumb {
    padding: 20px 0 30px;
  }
  .search-page{
    margin-bottom:20px;
  }
.search-page .ant-breadcrumb, .ant-breadcrumb a {
    color: #333333;
  }
.search-page .ant-breadcrumb a:hover {
    color: #92180b;
  }
.search-page .ant-breadcrumb > span:last-child {
    color: #92180b;
  }
.search-page .main-con {
    padding-bottom: 60px;
  }
  

  /*相关资源*/
.search-page .relate-resource {
      background: #f8f7f5;
  }
.search-page .relate-resource h3 {
      width: 100%;
      height: 66px;
      background: #d3c8a8;
      margin-bottom: 0px;
  }
.search-page .relate-resource h3 span {
      display: inline-block;
      width: 198px;
      height: 66px;
      padding: 0 30px;
      line-height: 66px;
      background: url(@/assets/images/title-bg-red.png) no-repeat left top;
      font-size: 26px;
      color: #fff;
      font-family: 'themeFont';
  }
.search-page .relate-resource .ant-list {
      padding: 20px 0;
  }
.search-page .relate-resource .ant-card {
      background-color: transparent;
  }
.search-page .relate-resource .ant-card-cover {
      padding: 10px 30px 20px;
  }
.search-page .relate-resource .ant-card-cover img {
      border: 2px solid #d5caac;
      box-shadow: 6px 6px 0 0 #e4e1db;
      cursor: pointer;
  }
.search-page .relate-resource .ant-card-body {
      padding: 0;
  }
.search-page .relate-resource .ant-card-meta-title {
      text-align: center;
  }
.search-page .relate-resource .ant-card-meta-title > a {
      color: #333333;
  }
.search-page .relate-resource .ant-card-meta-title > a:hover {
      color: #92180b;
  }
  
  /*李复文集*/
.search-page .anthology h3 {
      width: 100%;
      height: 66px;
      line-height: 66px;
      border-bottom: 1px solid #d3c8a8;
      font-size: 26px;
      color: #92180b;
      font-family: 'themeFont';
  }
.search-page .anthology .ant-list-split .ant-list-item {
      border-bottom: none;
      display: block;
  }
.search-page .anthology .ant-list-item-meta-title {
      font-size: 20px;
      margin-bottom: 10px;
  }
.search-page .anthology .ant-list-item-meta-title > a:hover {
      color: #92180b;
  }
.search-page .anthology .ant-list-item-meta-description > a {
      color: #674714;
  }
.search-page .anthology .ant-list-split .ant-list-item .brief {
      padding-top: 10px;
      font-size: 16px;
      line-height: 28px;
      font-family: "SimSun", "Microsoft YaHei", "Arial";
}

.search-page .anthology .hl { color: red; }