/*检索框*/
.search .con-box {
	height: 324px;
	background: url(@/assets/images/ser-bg.jpg) no-repeat 75% top;
}
.search .con-box h4 {
	font-size: 56px;
	line-height: 56px;
	font-family: 'themeFont';
	color: #641c15;
	text-align: center;
	padding-top: 75px;
}

.search .con-box .ant-select-selector .ant-select-selection-item {color: #000;}

.ser-box {
	width: 770px;
	margin: 0 auto;
}
.ser-box .ant-input-group-addon {
	height: 52px;
	background-color: transparent;
	border: 0px;
	border-radius: 0;
}
.ser-box .ant-select {
	font-size: 16px;
	color: #674714;
}
.ser-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ser-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
.ser-box .ant-input-affix-wrapper{
	height: 52px;
}
.ser-box .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 0px;
}
.ser-box .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background: #d3c8a8;
}
.ser-box .ant-select-single .ant-select-selector .ant-select-selection-item {
	line-height: 52px;
}
.ser-box .ant-select-single.ant-select-open .ant-select-selection-item,
.ser-box .ant-select-focused .ant-select-selector {
	color: #674714;
}
.ser-box .ant-select-arrow .anticon {
	display: none;
}
.ser-box .ant-select-arrow {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 6px solid #674714;
	margin-top: -2px;
}

/*按钮样式*/
.ser-box .ant-input-search-large .ant-input-search-button {
	width: 118px;
	height: 52px;
	box-shadow: none;
	background: #92180b;
	border-color: #92180b;
}
.ser-box .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
	border-radius: 0px;
}

/*检索框样式*/
.ser-box .ant-input-affix-wrapper,
.ser-box .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child){
	border-radius: 0px;
}
.ser-box .ant-input-affix-wrapper {
	border-color: #d3c8a8;
	margin-left: 20px;
	width: 513px;
}
.ser-box .ant-input-affix-wrapper:hover,
.ser-box .ant-input-affix-wrapper:focus,
.ser-box .ant-input-affix-wrapper-focused {
	border-color: #d3c8a8;
	box-shadow: 0 0 0 2px rgba(211, 200, 168, 0.6);
}

.anthology span.hl { color: red; }