.login-page .login .con-box {
	height: 728px;
	background: url(@/assets/images/login-banner.jpg) no-repeat right top;
	margin-bottom: 60px;
}
.login-page .login .con-box h4 {
	font-size: 56px;
	line-height: 56px;
	font-family: 'themeFont';
	color: #641c15;
	text-align: center;
	padding: 75px 0 60px;
	margin-bottom: 0px;
}
.login-page .login-box {
	width: 536px;
	padding: 48px 68px 24px;
	border: 1px solid #d2a099;
	background: #fff;
	margin: 0 auto;
}
/*form*/
.login-page .ant-form-item {
	margin-bottom: 20px;
}

.login-page .login-form .login-username {
	background:url(@/assets/images/icon-user.png) no-repeat 11px center;
}
.login-page .login-form .login-password {
	background:url(@/assets/images/icon-password.png) no-repeat 11px center;
}
.login-page .login-form .login-captcha  {
	background:url(@/assets/images/icon-captcha.png) no-repeat 11px center;
}
.login-page .login-form .login-form-forgot {
  float: right;
}
.login-page .login-form .captcha-item .ant-col-7{
	width: 100%;
	height: 44px;
	border-top: 1px solid #d3c8a8;
	border-bottom: 1px solid #d3c8a8;
}
.login-page .login-form .captcha-item .ant-col-7 img {
	width: 100%;
	height: 100%;
}
.login-page .login-form .captcha-item .ant-input {
	border-right: none;
}
/*input样式*/
.login-page .login-form .ant-input.login-input {
	height: 44px;
	border-color: #d3c8a8;
	color: #674714;
	padding: 4px 11px 4px 32px;
	border-radius: 0px;
}
.login-page .login-form .ant-input.login-input::-webkit-input-placeholder{
	color: #674714;
}
.login-page .login-form .ant-input.login-input:hover,
.login-page .login-form .ant-input.login-input:focus,
.login-page .login-form .ant-input.login-input.ant-input-focused {
	border-color: #d3c8a8;
	box-shadow: 0 0 0 2px rgba(211, 200, 168, 0.6);
}
/*checkbox样式*/
.login-page .login-form .login-chb .ant-checkbox-inner,
.login-page .login-form .login-chb .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.login-page .login-form .login-chb .ant-checkbox:hover .ant-checkbox-inner,
.login-page .login-form .login-chb .ant-checkbox-input:focus + .ant-checkbox-inner,
.login-page .login-form .login-chb .ant-checkbox-checked::after {
	border-color: #d3c8a8;
}
.login-page .login-form .login-chb .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #674714;
	border-color: #674714;
}
/*btn样式*/
.login-page .login-form .btn-captcha {
	width: 100%;
	height: 44px;
	line-height: 32px;
	border-color: #d3c8a8;
	border-radius: 0px;
	border-left: none;
	font-size: 16px;
	color: #92180b;
}
.login-page .login-form .btn-captcha:hover {
	border-color: #d3c8a8;
	color: #92180b;
}
.login-page .login-form .login-form-button {
	width: 100%;
	height: 44px;
	background-color: #92180b !important;
	border-color: #92180b !important;
	border-radius: 3px;
	font-size: 20px;
}
.login-page .login-form .login-form-button:hover {
	background-color: #87170b !important;
	border-color: #87170b !important;
}