@import '~antd/dist/antd.css';

/* common.css */

/*定义艺术字体*/
@font-face {
	font-family: 'themeFont';
	src: url('../assets/fonts/HanYiShuHunTiJian-1.ttf');
	font-weight: normal;
	font-style: normal;
	text-shadow: none;
	font-stretch: normal;
}

/*通用样式*/
@media screen and (min-width: 1600px) {
	.con-box {
		width: 1400px;
		margin: 0 auto;
	}
}
@media screen and (max-width: 1599px) {
	.con-box {
		width: 1200px;
		margin: 0 auto;
	}
}
a {
	color: #333;
}
a:hover {
	color: #92180b;
}

/*header*/
#header {
	padding: 34px 0;
}
#header .ant-col-18 {
	text-align: right;
}
#header .ant-btn {
	padding: 0 16px;
	height: 32px;
	line-height: 32px;
	border-radius: 16px;
	background: rgba(42, 31, 24, 0.34);
	border: none;
	margin: 8px;
}

/*footer*/
#footer {
	width: 100%;
	height: 180px;
	background: url(../assets/images/footer-bg.jpg) repeat-x;
}
#footer .link {
	padding: 40px 0;
	font-size: 18px;
	color: #efedea;
	margin-left: -15px;
	line-height: 20px;
}
#footer .link a {
	color: #efedea;
	font-family: 'themeFont';
	margin: 0 14px;
}
#footer .ant-col-18 p {
	font-size: 14px;
	color: #efedea;
	margin-bottom: 0px;
	line-height: 28px;
}
#footer .ant-col-18 p span {
	margin-right: 15px;
}
#footer .ant-col-6 .qrcode{
	float: right;
	margin: 50px 0 0 50px;
}
#footer .ant-col-6 .qrcode p {
	font-size: 14px;
	color: #efedea;
	text-align: center;
	padding-top: 8px;
}


img.gx-b-cover { border: 1px solid #efefef; box-shadow: 10px 10px 5px #efefef; }